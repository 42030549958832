.group {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}
.group .form-input {
  background: none;
  background-color: #fff;
  color: #446e7e;
  font-size: 23px;
  padding: 7px 15px 8px 20px;
  display: block;
  width: 100%;
  border: none;
  margin: 10px 0;
}
.group .form-input:focus {
  outline: none;
}
.group .form-input:focus ~ .form-input-label {
  top: -15px;
  left: 1%;
  font-size: 14px;
  color: #FFF;
  font-weight: bold;
}
.group input[type="password"] {
  letter-spacing: 0.3em;
}
.group .form-input-label {
  color: #446e7e;
  opacity: 1;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 25px;
  transition: 300ms ease all;
}
.group .form-input-label.shrink {
  top: -15px;
  left: 1%;
  font-size: 14px;
  color: #FFF;
  font-weight: bold;
}
.group .see-more-label {
  left: 80px !important;
}
